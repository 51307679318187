<script setup>
const props = defineProps({
  type: {
    type: String,
    default: 'hero',
    validator(value) {
      return ['hero', 'intro', 'gallery', 'home'].includes(value)
    },
  },
  sector: {
    type: String,
    required: true,
    validator(value) {
      return [
        'automotive',
        'architecture',
        'nautical',
        'product',
        'mobility',
        'wind',
      ].includes(value)
    },
  },
})

const nuxtApp = useNuxtApp()

// grid map for elements
const gridData = {
  hero: {
    desktop: {
      aspectRatio: 6.12,
      columns: 20,
      rows: 4,
      widthPerc: 1,
    },
    mobile: {
      aspectRatio: 1.57,
      columns: 4,
      rows: 4,
      widthPerc: 1,
    },
  },
  intro: {
    desktop: {
      aspectRatio: 0.68,
      columns: 4,
      rows: 6,
      widthPerc: 0.2,
    },
    mobile: {
      aspectRatio: 1,
      columns: 4,
      rows: 3,
      widthPerc: 0.6,
    },
  },
  gallery: {
    desktop: {
      aspectRatio: 1.5,
      columns: 5,
      rows: 4,
      widthPerc: 0.3,
    },
    mobile: {
      aspectRatio: 1,
      columns: 4,
      rows: 4,
      widthPerc: 0.5,
    },
  },
  home: {
    desktop: {
      aspectRatio: 1,
      columns: 4,
      rows: 4,
      widthPerc: 0.1,
    },
    mobile: {
      aspectRatio: 1,
      columns: 3,
      rows: 3,
      widthPerc: 0.35,
    },
  },
}

// p5.js variables
let p5Instance = null
const p5Container = ref(null)

// parallax variables
const parallaxContainer = ref()

const mouseX = ref(0)
const mouseY = ref(0)
const gyroscopeBeta = ref(0)
const gyroscopeGamma = ref(0)
const dx = ref(0)
const dy = ref(0)
const interpolationFactor = 0.025
const scalingFactorXMouse = 0.05
const scalingFactorYMouse = 0.1
const scalingFactorXGyroscope = 2000
const scalingFactorYGyroscope = 2000

// interpolation function
function lerp(a, b, n) {
  return (1 - n) * a + n * b
}

function initializePatternComponent() {
  // p5.js
  if (process.client && p5Container.value != null) {
    // compute width and height for the canvas
    let aspectRatio, columns, rows, width
    // const width = window.innerWidth

    if (window.innerWidth >= 768) {
      aspectRatio = gridData[props.type].desktop.aspectRatio
      columns = gridData[props.type].desktop.columns
      rows = gridData[props.type].desktop.rows
      width = window.innerWidth * gridData[props.type].desktop.widthPerc
    } else {
      aspectRatio = gridData[props.type].mobile.aspectRatio
      columns = gridData[props.type].mobile.columns
      rows = gridData[props.type].mobile.rows
      width = window.innerWidth * gridData[props.type].mobile.widthPerc
    }

    const height = width / aspectRatio

    // parameters
    let f
    let normalizedFMin = 0.25
    let normalizedFMax = 1
    let frequence = 3
    let shiftAmplitude = 0.5
    let shift
    let x, y

    // import p5.js and initialize it
    import('p5/lib/p5').then((p5) => {
      const sketch = (p) => {
        let dimW = 0
        let dimH = 0

        p.setup = () => {
          p.createCanvas(width, height)
          p.angleMode(p.DEGREES)
          dimW = width / columns
          dimH = height / rows
        }

        p.draw = () => {
          p.clear()
          p.noStroke()
          p.rectMode(p.CENTER)

          for (let i = 0; i < columns; i++) {
            for (let j = 0; j < rows; j++) {
              // calculate position
              x = i * dimW + dimW / 2
              y = j * dimH + dimH / 2

              // calculate oscillation
              f = p.sin(frequence * p.frameCount)

              // calculate phaseShift
              shift = p.dist(width / 2, height / 2, x, y)

              // aggiungere phase shift
              f = normalizeValue(
                p.sin(frequence * p.frameCount + shiftAmplitude * shift),
                -1,
                1,
                normalizedFMin,
                normalizedFMax
              )

              drawSectorSymbol(x, y, f * dimW, f * dimH, props.sector)
            }
          }
        }

        function drawSectorSymbol(x, y, w, h, sector) {
          let size

          switch (sector) {
            case 'automotive':
              // line pattern
              p.fill('#FC2A2A')
              p.rect(x, y, w * 0.2, h * 0.025)
              // line pattern end

              break
            case 'architecture':
              // square pattern
              size = w * 0.065
              p.fill('#59C9E0')
              p.rect(x, y, size, size)
              // square pattern end

              break
            case 'nautical':
              // triangle pattern
              const halfWidthWT = (w * 0.1) / 2
              const halfHeightWT = (w * 0.125) / 2
              const bottomLeftX = x - halfWidthWT
              const bottomLeftY = y + halfHeightWT
              const bottomRightX = x + halfWidthWT
              const bottomRightY = y + halfHeightWT
              const topX = x - halfWidthWT
              const topY = y - halfHeightWT
              p.fill('#FFD801')
              p.triangle(
                bottomLeftX,
                bottomLeftY,
                topX,
                topY,
                bottomRightX,
                bottomRightY
              )
              // triangle pattern end

              break
            case 'product':
              // circle pattern
              size = w * 0.065
              p.fill('#FF2BCE')
              p.circle(x, y, size)
              // circle pattern end

              break
            case 'mobility':
              // X pattern
              size = w * 0.04
              const p1 = {x: x - size, y: y - size}
              const p2 = {x: x - size, y: y + size}
              const p3 = {x: x + size, y: y + size}
              const p4 = {x: x + size, y: y - size}
              p.stroke('#0FFF6B')
              p.strokeWeight(2)
              p.strokeCap(p.SQUARE)
              p.line(p1.x, p1.y, p3.x, p3.y)
              p.line(p2.x, p2.y, p4.x, p4.y)
              p.noStroke()
              // X pattern end

              break
            case 'wind':
              // wave pattern
              const sizeHeight = h * 0.06
              const sizewidth = w * 0.1
              size = w * 0.1
              const p1M = {x: x - sizewidth, y: y - sizeHeight}
              const p2M = {x: x - sizewidth * 0.4, y: y + sizeHeight}
              const p3M = {x: x + sizewidth, y: y + sizeHeight}
              const p4M = {x: x + sizewidth * 0.4, y: y - sizeHeight}

              p.noFill()
              p.stroke('#FF7600')
              p.strokeWeight(3)
              p.strokeCap(p.SQUARE)
              p.bezier(p1M.x, p1M.y, p4M.x, p4M.y, p2M.x, p2M.y, p3M.x, p3M.y)
              p.noStroke()
              // wave pattern end
              break
            default:
              break
          }
        }
      }

      p5Instance = new p5.default(sketch, p5Container.value)
    })

    function normalizeValue(
      value,
      minValue,
      maxValue,
      minNormalized,
      maxNormalized
    ) {
      return (
        ((value - minValue) / (maxValue - minValue)) *
          (maxNormalized - minNormalized) +
        minNormalized
      )
    }
  }

  const hasNoFinePointer = !window.matchMedia('(pointer:fine)').matches
  if (hasNoFinePointer) {
    if (typeof DeviceOrientationEvent?.requestPermission === 'function') {
      // request permission to use orientation sensor
      DeviceOrientationEvent.requestPermission()
        .then((permissionState) => {
          if (permissionState === 'granted') {
            // Permission granted, handle device motion
            window.addEventListener('deviceorientation', handleOrientation)
            requestAnimationFrame(updateMovementGyroscope)
          } else {
            // Permission denied or dismissed by the user
            console.log('Motion sensor permission denied by the user.')
          }
        })
        .catch((error) => {
          // An error occurred while requesting permission
          console.error('Error requesting motion sensor permission:', error)
        })
    } else {
      // For older browsers that do not support the Permissions API
      console.log('Motion sensor permission API is not supported.')
      window.addEventListener('deviceorientation', handleOrientation)
      requestAnimationFrame(updateMovementGyroscope)
    }
  }
  if (
    !hasNoFinePointer ||
    typeof DeviceOrientationEvent?.requestPermission !== 'function'
  ) {
    document.addEventListener('mousemove', handleMouseMove)
    requestAnimationFrame(updateMovement)
  }
}

function resetPatternComponent() {
  // p5.js
  if (p5Instance && p5Instance.canvas) {
    const canvasElement = p5Instance.canvas.parentElement

    if (canvasElement) {
      canvasElement.removeChild(p5Instance.canvas)
    }

    p5Instance.remove()
    p5Instance = null
  }

  // parallax
  window.removeEventListener('mousemove', handleMouseMove)
}

let initialized = false

onMounted(() => {
  // if (!initialized) {
  // initializePatternComponent()
  // initialized = true
  // }

  setTimeout(() => {
    initializePatternComponent()
  }, 950)
})

// nuxtApp.hook('page:transition:finish', ({from, to, done}) => {
//   console.log(
//     'Pattern - hook page:transition:finish - initialize: ',
//     initialized
//   )
//   initializePatternComponent()
// })

onUnmounted(() => {
  resetPatternComponent()
})

// parallax function to handle gyroscope orientation
function handleOrientation(event) {
  const normalizedBeta = (Math.round(event.beta) - 90) / 90 // Normalize beta (pitch) value to range -1 to 1
  const normalizedGamma = Math.round(event.gamma) / 90 // Normalize gamma (roll) value to range -1 to 1

  // Update gyroscope values
  gyroscopeBeta.value = normalizedBeta
  gyroscopeGamma.value = normalizedGamma
}

// parallax function to update target movement with gyroscope-based interpolation
function updateMovementGyroscope() {
  if (parallaxContainer.value) {
    const containerRect = parallaxContainer.value.getBoundingClientRect()
    const containerCenterX = containerRect.left + containerRect.width / 2
    const containerCenterY = containerRect.top + containerRect.height / 2

    // Adjust the lerp computation for gyroscope values (-1 to 1)
    dx.value = lerp(
      containerCenterX,
      containerCenterX + gyroscopeGamma.value * scalingFactorXGyroscope,
      interpolationFactor
    )
    dy.value = lerp(
      containerCenterY,
      containerCenterY + gyroscopeBeta.value * scalingFactorYGyroscope,
      interpolationFactor
    )

    const moveX = containerCenterX - dx.value
    const moveY = containerCenterY - dy.value

    parallaxContainer.value.style.setProperty(
      '--parallax-translate-x',
      `${moveX}px`
    )
    parallaxContainer.value.style.setProperty(
      '--parallax-translate-y',
      `${moveY}px`
    )
  }

  requestAnimationFrame(updateMovementGyroscope)
}

// parallax function to manage mouse movement
function handleMouseMove(event) {
  mouseX.value = event.clientX
  mouseY.value = event.clientY
}

// parallax function to update target movement (mouse driven) with interpolation
function updateMovement() {
  if (parallaxContainer.value) {
    const containerRect = parallaxContainer.value.getBoundingClientRect()
    const containerCenterX = containerRect.left + containerRect.width / 2
    const containerCenterY = containerRect.top + containerRect.height / 2

    dx.value = lerp(dx.value, mouseX.value, interpolationFactor)
    dy.value = lerp(dy.value, mouseY.value, interpolationFactor)

    const moveX = (containerCenterX - dx.value) * scalingFactorXMouse
    const moveY = (containerCenterY - dy.value) * scalingFactorYMouse

    parallaxContainer.value.style.setProperty(
      '--parallax-translate-x',
      `${moveX}px`
    )
    parallaxContainer.value.style.setProperty(
      '--parallax-translate-y',
      `${moveY}px`
    )
  }

  requestAnimationFrame(updateMovement)
}
</script>

<template>
  <div class="p5Container-wrapper parallax-container" ref="parallaxContainer">
    <div class="p5Container" ref="p5Container"></div>
  </div>
</template>

<style lang="scss" scoped>
.p5Container-wrapper {
  canvas {
    width: 100% !important;
  }
}

.parallax-container {
  --parallax-translate-x: 0;
  --parallax-translate-y: 0;
  transform: translate(
    var(--parallax-translate-x),
    var(--parallax-translate-y)
  );
}
</style>
