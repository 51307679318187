export const filterPictureSourceTags = (list) => {
  return list.filter((elem) => elem.media !== 'image')
}

export const filterPictureImageTag = (list) => {
  return list.filter((elem) => elem.media === 'image')
}

export const getVideoType = (url) => {
  const extension = url.split('.').pop().toLowerCase()

  switch (extension) {
    case 'mp4':
      return 'video/mp4'
    case 'webm':
      return 'video/webm'
    case 'ogg':
      return 'video/ogg'
    default:
      return 'video/mp4'
  }
}
