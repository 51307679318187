import dayjs from 'dayjs'
import 'dayjs/locale/en' // English locale
import 'dayjs/locale/it' // Italian locale
import 'dayjs/locale/de' // German locale
import 'dayjs/locale/zh-cn' // Chinese (Simplified) locale

export default function useDateFormatter(date, language) {
  // Set the locale based on the language parameter
  switch (language) {
    case 'en':
      dayjs.locale('en')
      break
    case 'it':
      dayjs.locale('it')
      break
    case 'de':
      dayjs.locale('de')
      break
    case 'zh':
      dayjs.locale('zh-cn')
      break
    default:
      dayjs.locale('en') // Default to English if the language is not recognized
  }

  const formattedDate = dayjs(date).format(
    language === 'it' ? 'DD MMMM YYYY' : 'MMMM DD, YYYY'
  )

  return formattedDate
}
